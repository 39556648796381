import React from 'react';
import SweatRateCalculatorComponent from '../../components/tools/sweatratecalculator/sweatratecalculator';
import Layout from '../../layout/layout';
import Seo from '../../layout/seo';

class SweatRateCalculator extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo lang="en" title="Sweat Rate Calculator" location={this.props.location} />
        <SweatRateCalculatorComponent />
      </Layout>
    )
  }
}

export default SweatRateCalculator;